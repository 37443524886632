
import { PaginatedList } from "./list";

export interface CreateApplication {
    name: string,
    isVisible: string,
    password?: string,
    description?: string,
}

export interface UpdateApplication extends CreateApplication {
    id: string | undefined,
}

export interface Application extends CreateApplication {
    id: string | undefined,
    iconFn?: string | undefined,
    fileFn?: string | undefined,
}


export enum CreateApplicationResponse {
    Success = 'Appication has been added.',
}

export enum UpdateApplicationResponse {
    Success = 'Appication has been updated.',
    ApplicationNotFound = "ApplicationNotFound",
}

export enum DeleteApplicationResponse {
    Success = 'Application has been deleted.',
    ApplicationNotFound = 'Appication not found.'
}

export type GetOneApplicationResponse = Omit<Application, 'photoFn'> | null
export type GetPaginatedListOfApplication = PaginatedList<GetOneApplicationResponse>