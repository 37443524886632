import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { Alert, AlertType, Button, Checkbox, Form, Input, InputType, Loader, Textarea } from "../../../components";
import { CreateApplicationResponse, RegexPattern, UpdateApplicationResponse } from "../../../types";
import './CreateApplicationForm.scss';
import { get, getFile, patch, post } from "../../../functions";

interface CreateApplicationFormProps {
    id?: string;
    onSubmitFn: Function,
}


export const CreateApplicationForm = (props: CreateApplicationFormProps) => {
    const { id, onSubmitFn } = props
    const [name, setName] = useState<string>('')
    const [isVisible, setIsVisible] = useState<boolean>(false)
    const [description, setDescription] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [iconSrc, setIconSrc] = useState<string>('')
    const [iconFile, setIconFile] = useState<File | null>(null);
    const [apkName, setApkName] = useState<string>('')
    const [fileFile, setFileFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [alert, setAlert] = useState<ReactNode>(null)

    const getApplication = useCallback(async () => {
        setIsLoading(() => true)
        const application = await get(`/application/get-one/${id}`)
        const icon = await getFile(`/application/get-icon/${id}`);
        setIsVisible(application.isVisible === 'tak' ? true : false)
        setName(application.name)
        setDescription(application.description)
        setPassword(application.password)
        setIconSrc(() => icon.url);
        setIsLoading(() => false)
    }, [id])


    useEffect(() => {
        if (id) {
            getApplication()
        } else {
            setIsLoading(() => false)
        }

    }, [getApplication, id])



    const handleChangeDescription = (value: string) => {
        setDescription(value)
    }

    const handleChangeName = (name: string) => {
        setName(name)
    }

    const handleChangeIsVisible = (value: boolean) => {
        console.log(value)
        setIsVisible(value)
    }
    const handleChangePassword = (value: string) => {
        setPassword(value)
    }

    const handleChangeIcon = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setIconSrc(reader.result as string);
            };
            reader.readAsDataURL(file);
            setIconFile(file)
        }
    };

    const handleChangeApk = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setApkName(file.name as string);
            };
            reader.readAsDataURL(file);
            setFileFile(file)
        }
    };



    const handleSubmit = async () => {
        setIsLoading(true)
        let files: any[] | undefined = []
        const icon = iconFile ? { label: 'icon', content: iconFile } : undefined
        const apk = fileFile ? { label: 'file', content: fileFile } : undefined
        if (icon) {
            files.push(icon)
        }
        if (apk) {
            files.push(apk)
        }
        if (files.length === 0) {
            files = undefined
        }


        let response = undefined
        const app = {
            name: name,
            isVisible: isVisible === true ? "tak" : "nie",
            description: description,
            password: password
        }
        if (id) {
            response = await patch('/application/update', {
                id: id,
                ...app,
            },
                files,
            )
        } else {

            response = await post('/application/add',
                app,
                files,
            )

        }
        setIsLoading(false)
        if (response === CreateApplicationResponse.Success || response === UpdateApplicationResponse.Success) {
            onSubmitFn()
        } else {
            setAlert(<Alert message={'Błąd'} className={''} type={AlertType.Error} />)
            setTimeout(() => {
                setAlert(null)
            }, 2000)
        }


    }

    return (
        <Form onSubmitFn={handleSubmit} className={'admin-form'}>
            {alert}
            <Input className='admin-form__input' label={'Nazwa'} type={InputType.Text} value={name} validationRegex={RegexPattern.Minimum2Characters} validationErrorMessage={'Nazwa powinna skłądać się z co najmniej 2 znaków.'} onChangeFn={handleChangeName} />
            <Input className='admin-form__input' label={'Hasło'} type={InputType.Text} value={password} onChangeFn={handleChangePassword} />
            <Input className={"admin-form__input"} type={InputType.File} label={'Ikona'} onChangeFn={handleChangeIcon} photo={iconSrc} />
            <Input className={"admin-form__input"} type={InputType.File} label={'APK'} onChangeFn={handleChangeApk} fileName={apkName} />
            <Textarea className={"admin-form__input"} value={description} onChangeFn={handleChangeDescription} label="Opis" />
            <Checkbox className={"admin-form__input"} text={"Widoczna na stronie"} onChangeFn={handleChangeIsVisible} checked={isVisible} />
            <Button className={"admin-form__button"} text={"Zapisz"} disabled={false}></Button>
            {isLoading && <Loader />}

        </Form>
    )
}
