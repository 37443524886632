import React from 'react';
import './App.scss';
import { AuthContextProvider } from './contexts';
import { BrowserRouter } from 'react-router-dom';
import { Main } from './views';

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Main />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
