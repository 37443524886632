import { Route, Routes } from 'react-router-dom';
import { Admins, ChangePwdView, LoginView, SendCodeView } from '../..//views';
import './PageContent.scss';
import { Applications } from '../../views/Applications';


export const PageContent = () => {
    return (
        <section className='page-content'>


            <Routes>
                <Route path='/login' element={<LoginView />} />
                <Route path='/reset-pwd' element={<SendCodeView />} />
                <Route path='/change-pwd/:id/:code' element={<ChangePwdView />} />
                <Route path='/admins' element={<Admins />} />
                <Route path='/applications' element={<Applications />} />

            </Routes>




        </section>
    )
}