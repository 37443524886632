import { ReactNode, useState } from "react";
import { Button, Column, ConfirmDelete, ContentWrapper, Modal, Table } from "../../components"
import { deleteItem, get } from "../../functions";
import { CreateApplicationForm } from "./Applications/CreateApplicationForm";
import './Applications.scss';


export const Applications = () => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modal, setModal] = useState<ReactNode | null>(null);
    const [tableKey, setTableKey] = useState(0);

    const columns: Column[] = [

        {
            title: "Nazwa",
            key: "name",
            width: 200
        },
        {
            title: "Widoczna",
            key: "isVisible",
            width: 200
        }, {
            title: "Hasło",
            key: "password",
            width: 200
        },


    ];

    const editApplication = (id: string) => {
        const edit = <CreateApplicationForm id={id} onSubmitFn={changeTableKey} />
        setModal(<Modal className={"create-modal"} children={edit} title={"Edytuj aplikację"} closeFn={handleCloseModal} />)
        setIsModalOpen(true)
    }


    const createApplication = () => {
        const create = <CreateApplicationForm onSubmitFn={changeTableKey} />
        setModal(<Modal className={"create-modal"} children={create} title={"Utwórz aplikację"} closeFn={handleCloseModal} />)
        setIsModalOpen(true)
    }

    const handleDeleteApplication = async (id: string) => {
        const application = await get(`/application/get-one/${id}`)
        const deleteApplication = () => {
            deleteItem(`/application/delete/${id}`)
            changeTableKey()
        }
        const confirm = <ConfirmDelete message={'Czy na pewno chcesz usunąć aplikację:'} item={` ${application.name}`} deleteFn={deleteApplication} cancelFn={handleCloseModal} />
        setModal(<Modal className={"delete-modal"} children={confirm} title={"Usuń aplikację"} closeFn={handleCloseModal} />)
        setIsModalOpen(true)
    }

    const changeTableKey = () => {
        setTableKey(() => tableKey + 1);
        setIsModalOpen(false)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    return (
        <div className='admins'>
            <ContentWrapper className={'admins__wrapper admins-wrapper'} title={'Aplikacje'}>
                <Table className="admins-table " key={tableKey} sourceUrl={'/application/get-all/'} columns={columns} actions={true} orderBy="name" editItemFn={editApplication} deleteItemFn={handleDeleteApplication} />
                <Button className={'content-wrapper__add-button'} text={'Dodaj'} disabled={false} onClickFn={createApplication} />
            </ContentWrapper>
            {isModalOpen && modal}
        </div>
    )
}