
import './PageHeader.scss';
import { Logo } from '../Logo';
import { HeaderMenu } from './HeaderMenu';

interface PageHeaderProps {
    isNavbarOpen: boolean;
    setIsNavbarOpen: Function;
}

export const PageHeader = (props: PageHeaderProps) => {
    const { isNavbarOpen, setIsNavbarOpen } = props;

    return (
        <section className='page-header'>
            <div className='header'>
                <Logo className='header__logo' logo={'LOGOURL'} />
                <HeaderMenu className='header__menu' isNavbarOpen={isNavbarOpen} setIsNavbarOpen={setIsNavbarOpen} />



            </div>
        </section >
    )
}