
import clsx from 'clsx';
import { SlMenu } from "react-icons/sl";
import { IoIosLogOut } from "react-icons/io";
import { Icon } from '../../../components';
import './HeaderMenu.scss';

import { get } from '../../../functions';
import { useNavigate } from 'react-router';


interface HeaderMenuProps {
    className?: string;
    isNavbarOpen: boolean;
    setIsNavbarOpen: Function;
}

export const HeaderMenu = (props: HeaderMenuProps) => {
    const { className, isNavbarOpen, setIsNavbarOpen } = props;

    const navigate = useNavigate();

    const toolsClass = clsx('header-menu__tools tools')




    const menuClass = clsx(`${className} header-menu`);

    const openNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    }



    const handleLogout = async () => {
        await get(`/auth/logout`);
        navigate('/login');
    }








    return (
        <div className={menuClass} >
            < Icon className='header-menu__icon header-menu__icon--navbar' icon={< SlMenu />} onClickFn={openNavbar} />
            <div className={toolsClass}>

                < Icon className='tools__icon tools__icon--logout' icon={<IoIosLogOut />} onClickFn={handleLogout} />
            </div>

        </div >
    )
}