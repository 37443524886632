import clsx from 'clsx';
import './Logo.scss';
import logoUrl from '../../assets/images/logo.jpg';

interface LogoProps {
    logo?: string | undefined
    className?: string
    onClickFn?: Function
};

export const Logo = (props: LogoProps) => {
    const { className, onClickFn } = props;
    const logoClass = clsx(`${className} logo`);

    const handleClick = () => {
        if (onClickFn) {
            onClickFn()
        }
    }
    return (
        <div className={logoClass} style={{ backgroundImage: `url(${logoUrl})` }} onClick={handleClick}></div>
    )
}