import clsx from 'clsx';
import './PageNavbar.scss';
import { Link } from 'react-router-dom';
import { NavbarLink } from './Link';
import { FaUserCog } from 'react-icons/fa';
import { FaAndroid } from "react-icons/fa6";

interface PageNavbarProps {
    isNavbarOpen: boolean;
    setIsNavbarOpen: Function;
}

export const PageNavbar = (props: PageNavbarProps) => {
    const { isNavbarOpen, setIsNavbarOpen } = props;

    const navbarClass = clsx('page-navbar', { 'page-navbar--opened': isNavbarOpen })

    const hendleClick = () => {
        setIsNavbarOpen(false)
    }

    return (
        <section className={navbarClass}>
            <div onClick={hendleClick}><Link to={'/admins'}><NavbarLink icon={<FaUserCog />} name={"Administratorzy"} /></Link></div>
            <div onClick={hendleClick}><Link to={'/applications'}><NavbarLink icon={<FaAndroid />} name={"Aplikacje"} /></Link></div>
        </section>
    )
}
