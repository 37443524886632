import { ReactNode, useCallback, useEffect, useState } from "react";
import { Alert, AlertType, Button, Form, Input, InputType, Loader } from "../../../components";
import { CreateAdminResponse, RegexPattern, UpdateAdminResponse } from "../../../types";
import './CreateAdminForm.scss';
import { get, patch, post } from "../../../functions";

interface CreateAdminFormProps {
    id?: string;
    onSubmitFn: Function,
}


export const CreateAdminForm = (props: CreateAdminFormProps) => {
    const { id, onSubmitFn } = props
    const [email, setEmail] = useState<string>('')
    const [name, setName] = useState<string>('')
    const [surname, setSurname] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [alert, setAlert] = useState<ReactNode>(null)

    const getAdmin = useCallback(async () => {
        setIsLoading(() => true)
        const admin = await get(`/admin/get-one/${id}`)
        setEmail(admin.email)
        setName(admin.name)
        setSurname(admin.surname)
        setIsLoading(() => false)
    }, [id])


    useEffect(() => {
        if (id) {
            getAdmin()
        } else {
            setIsLoading(() => false)
        }

    }, [getAdmin, id])



    const handleChangeEmail = (email: string) => {
        setEmail(email)
    }

    const handleChangeName = (name: string) => {
        setName(name)
    }

    const handleChangeSurname = (surname: string) => {
        setSurname(surname)
    }



    const handleSubmit = async () => {
        let response;
        if (id) {
            response = await patch('/admin/update', {
                id: id,
                role: "SuperAdmin",
                name: name,
                surname: surname,
                email: email,
            })
        } else {
            response = await post('/admin/add', {
                role: "SuperAdmin",
                name: name,
                surname: surname,
                email: email,
            })
        }
        console.log(response, UpdateAdminResponse.Success)
        if (response === CreateAdminResponse.Success || response === UpdateAdminResponse.Success) {
            onSubmitFn()
        } else {
            setAlert(<Alert message={'Błąd'} className={''} type={AlertType.Error} />)
            setTimeout(() => {
                setAlert(null)
            }, 2000)
        }


    }

    return (
        <Form onSubmitFn={handleSubmit} className={'admin-form'}>
            {alert}
            <Input className='admin-form__input' label={'Imię'} type={InputType.Text} value={name} validationRegex={RegexPattern.Minimum2Characters} validationErrorMessage={'Imię powinno skłądać się z co najmniej 2 znaków.'} onChangeFn={handleChangeName} />
            <Input className='admin-form__input' label={'Nazwisko'} type={InputType.Text} value={surname} validationRegex={RegexPattern.Minimum2Characters} validationErrorMessage={'Nazwisko powinno skłądać się z co najmniej 2 znaków.'} onChangeFn={handleChangeSurname} />
            <Input className='admin-form__input' label={'E-mail'} type={InputType.Text} value={email} validationRegex={RegexPattern.Email} validationErrorMessage={'nieprawidłowy adres e-mail'} onChangeFn={handleChangeEmail} />
            <Button className={"admin-form__button"} text={"Zapisz"} disabled={false}></Button>
            {isLoading && <Loader />}

        </Form>
    )
}
